<template>
  <v-row no-gutters>
    <v-select label="Operation" :items="unused_operations" v-model="operationAssignment" item-text="operation_name" item-value="id"></v-select>
    <v-btn class="mx-2 mt-1" @click="createOperation" color="primary">Ajouter</v-btn>
    <v-progress-circular v-if="creating" indeterminate color="primary"></v-progress-circular>
  </v-row>
</template>
<script>
import {AssignmentRepository} from "@/repository/assignments";

export default {
  name: 'AddOperationToCommande',
  props: {
    unused_operations: {},
    commande: {},
  },
  data() {
    return {
      creating: false,
      operationAssignment: null,
    }
  },
  methods: {
    async createOperation() {
      this.creating = true;
      let repository = new AssignmentRepository();
      try {
        await repository.post({operation: this.operationAssignment, commande: this.commande.id});
      } catch (e) {
        this.$store.dispatch("annonce/annonceError", "Une erreur est survenue.");
      }
      finally {
        this.creating = false;
        this.$emit("reload");
      }

    }
  }

}
</script>