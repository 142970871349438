<template>
  <v-dialog width="600" v-model="dialog">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-if="operation === 'create'" v-on="on" v-bind="attrs" color="primary">Ajouter une commande</v-btn>
      <v-btn v-else v-on="on" v-bind="attrs" color="primary">Modifier une commande</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title v-if="operation === 'create'" class="white--text">Ajouter la commande</v-toolbar-title>
        <v-toolbar-title v-else class="white--text">Mettre à jour la commande</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-select :items="entreprises" v-model="commandeEntreprise" item-text="name" item-value="id"></v-select>
        <v-text-field v-model="dureeTravaux" label="Durée des travaux"></v-text-field>
        <v-menu v-model="menu_date" max-width="100%" class="ma-0 pa-0" :close-on-content-click="false"
                :close-on-click="true">
          <template v-slot:activator="{on, attrs}">
            <v-text-field v-on="on" v-bind="attrs" label="Date de début des travaux"
                          :value="getFormattedDate(dateTravaux)"></v-text-field>
          </template>
          <v-card>
            <v-date-picker v-model="dateTravaux"></v-date-picker>
            <v-card-actions>
              <v-btn @click="menu_date = false" text color="primary">Fermer</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-text-field v-if="devis.type === 'import'" v-model="montantTotal" label="Montant total HT"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="createCommande" v-if="operation === 'create'" :disabled="creating" color="primary">Créer la
          commande
        </v-btn>
        <v-btn @click="updateCommande" v-else :disabled="creating" color="primary">Mettre à jour la commande</v-btn>
        <v-progress-circular indeterminate color="primary" v-if="creating"></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {EntrepriseRepository} from "@/repository/entreprises";
import {CommandeRepository} from "@/repository/commandes";
import {DateUtilMixin} from "@/mixins/DateUtilMixin";

export default {
  name: 'CommandeForm',
  mixins: [DateUtilMixin],
  props: {
    operation: String,
    /** @type Devis */
    devis: Object,
    commande: Object,
  },
  async mounted() {
    /** @type IApi<Entreprise>*/
    let repository = new EntrepriseRepository();
    this.entreprises = await repository.fetch();
  },
  data() {
    return {
      /** @type Entreprise[] */
      entreprises: [],
      loading: false,
      dialog: false,
      creating: false,
      montantTotal: this.operation === "update" ? this.commande.montant_total : null,
      commandeEntreprise: this.operation === "update" ? this.commande.entreprise.id : null,
      dureeTravaux: this.operation === "update" ? this.commande?.duree_travaux : null,
      dateTravaux: this.operation === "update" ? this.commande?.date_travaux : null,
      menu_date: false,
    }
  },
  methods: {
    async createCommande() {
      this.creating = true;
      let repository = new CommandeRepository();
      try {
        await repository.post({
          devis: this.devis.id, entreprise: this.commandeEntreprise,
          date_travaux: this.dateTravaux, duree_travaux: this.dureeTravaux, montant_total: this.montantTotal,
        });
      } catch (e) {
        this.$store.dispatch("annonce/annonceError", "Une erreur est survenue.");
      } finally {
        this.creating = false;
        this.dialog = false;
        this.$emit("reload");
      }

    },
    async updateCommande() {
      this.creating = true;
      let repository = new CommandeRepository();
      try {
        await repository.update({
          id: this.commande.id, devis: this.devis.id, entreprise: this.commandeEntreprise,
          date_travaux: this.dateTravaux, duree_travaux: this.dureeTravaux, montant_total: this.montantTotal,
        });
      } catch (e) {
          await this.$store.dispatch("annonce/annonceError", "Une erreur est survenue.");
      }
      this.creating = false;
      this.dialog = false;
      this.$emit("reload");
    },
  }
}
</script>
