<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="primary" v-on="on" v-bind="attrs">
        Payer la commande
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" class="white--text">
        <v-toolbar-title class="white--text">Payer la commande</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="payCommandeForm">
          <v-select :rules="[notNullNorEmpty]" v-model="saisieSource" :items="types_decaissement" label="Source"></v-select>
          <v-text-field :rules="[notNullNorEmpty]" label="Montant" v-model="reglementMontant"
                        type="number"></v-text-field>
          <date-field :rules="[notNullNorEmpty]" label="Date de la facture" v-model="factureDate"></date-field>
          <v-text-field :rules="[notNullNorEmpty]" label="Référence de la facture" v-model="factureReference"
                        type="text"></v-text-field>
          <v-select label="Statut" :items="statuses" disabled item-text="name" item-value="value"
                    v-model="reglementStatus"></v-select>
          <v-file-input :rules="[notNullNorEmpty]" :disabled="useBonAsFacture" v-model="reglementFile"
                        label="Facture liée"></v-file-input>
          <v-checkbox v-model="useBonAsFacture" label="Utiliser le bon comme justificatif"></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" v-if="creating">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-row>
        <v-btn @click="createCommandePayment" :disabled="creating" color="primary">
          Créer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {TYPES_DECAISSEMENT} from "@/constants/Financement";
import DateField from "@/components/base/DateField.vue";
import {ReglementRepository} from "@/repository/reglement";
import {ValidationRulesMixin} from "@/mixins/ValidationRulesMixin";

export default {
  name: 'PayCommandeForm',
  components: {DateField},
  mixins: [ValidationRulesMixin],
  props: {
    devis: Object,
    commande: Object,

  },
  data() {
    return {
      saisieSource: null,
      reglementStatus: "waiting",
      reglementFile: null,
      factureDate: null,
      factureReference: null,
      useBonAsFacture: null,
      reglementMontant: this.getInitialMontant(this.commande),
      dialog: false,
      creating: false,
      statuses: [{value: 'waiting', name: 'En attente'}],
    }
  },
  computed: {
    types_decaissement() {
      return TYPES_DECAISSEMENT.filter(type => type.toLowerCase().includes("travaux"));
    }
  },
  methods: {
    getInitialMontant(commande) {
      let totalPaid = 0;
      for(let reglement of commande.reglements) {
        totalPaid += reglement.montant;
      }
      let toPay = commande.total - totalPaid;
      return Math.round(toPay * 100) / 100;

    },
    async createCommandePayment() {
      if (this.$refs.payCommandeForm.validate()) {
        this.creating = true;
        try {
          let repository = new ReglementRepository();
          let result = await repository.createFacturePayment({
            source: this.saisieSource,
            date_facture: this.factureDate,
            reference_facture: this.factureReference,
            montant: this.reglementMontant,
            status: this.reglementStatus,
            dossier: this.devis.dossier,
            entreprise: this.commande.entreprise.id,
            file: this.reglementFile,
            use_bon: this.useBonAsFacture,
          });
          if (result) {
            this.$emit("reload-commandes");
            await this.$store.dispatch("annonce/annonceSuccess", "Règlement ajouté.");
          }
        } finally {
          this.creating = false;
          this.dialog = false;
        }
      }
    }
  }
}
</script>