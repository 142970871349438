import {Api} from "@/repository/api";
import axios from "axios";
import store from "@/store";

export class CommandeRepository extends Api {
    constructor() {
        super("commandes");
    }

    /**
     *
     * @param id
     * @returns {Promise<Commande|*[]>}
     */
    async fetchByDevisId(id) {
        try {
            let response = await axios.get(`${this.url}/?devis=${id}`, store.getters["auth/getAuth"]);
            return response.data;
        } catch (e) {
            await store.dispatch("annonce/annonceError", "Une erreur est survenue");
            return [];
        }
    }
}