<template>
  <v-dialog max-width="600" v-model="dialog">
    <template v-slot:activator="{on, attrs}">
      <v-btn color="red" icon v-on="on" v-bind="attrs"><v-icon>mdi-trash-can</v-icon></v-btn>
    </template>
    <v-card>
      <v-toolbar color="red">
        <v-toolbar-title class="white--text">Supprimer la commande</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-3">
        Êtes-vous sur de vouloir supprimer la commande ?
      </v-card-text>
      <v-card-actions>
        <v-btn class="white--text" @click="deleteCommande" :disabled="deleting" color="red">Supprimer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {CommandeRepository} from "@/repository/commandes";

export default {
  name: 'DeleteCommandeForm',
  props: {
    commande: Object,
  },
  data() {
    return {
      deleting:false,
      dialog: false,
    }
  },
  methods: {
    async deleteCommande() {
      this.deleting = true;
      let repository = new CommandeRepository();
      let result = await repository.delete(this.commande);
      if(result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Commande supprimé");
        this.$emit("reload");
      }
      this.dialog = false;
      this.deleting = false;
    }
  }
}
</script>