<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" color="primary">Ajouter bon de commande existant</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary" class="white--text">
        <v-toolbar-title class="white--text">Ajouter le bon de commande</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-file-input label="Bon de commande" v-model="bonFile"></v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center" align="center" v-if="creating">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-row>
        <v-btn @click="addBonFile" :disabled="creating" color="primary">
          Modifier
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {CommandeRepository} from "@/repository/commandes";

export default {
  name: 'AddBonCommande',
  props: {
    commande: Object,
  },
  data() {
    return {
      dialog: false,
      creating: false,
      bonFile: null,
    }
  },
  methods: {
    async addBonFile() {
      this.creating = true;
      let repo = new CommandeRepository();
      let result = await repo.updateWithFile({id: this.commande.id, bon: this.bonFile});
      if(result) {
        await this.$store.dispatch("annonce/annonceSuccess", "Commande mis à jour");
      }
      this.$emit("reload");
      this.dialog = false;
      this.creating = false;
    }
  }
}
</script>